<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">웹 발송 조회</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">웹 발송 목록</h3>
          </template>
          <!--          상세보기 레이어-->
          <div>
            <div class="col-lg-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="form-inline " style="margin-bottom: 1rem;">
                <div class="mb-0 col-md-4 form-group pl-0 pr-0">
                  <label class="col-md-4 col-form-label form-control-label"><span class="text-danger">*</span>메시지유형</label>
                  <div class="col-md-8 pl-0 pr-0 form-inline">
                    <div class="pl-0">
                      <el-select v-model="search.messageType"
                                 filterable
                                 placeholder="메시지유형 선택">
                        <el-option v-for="option in MessageTypeOptions"
                                   :key="option.value"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="mb-0 col-md-6 form-group pl-0 pr-0">
                  <label class="col-md-2 col-form-label form-control-label"><span class="text-danger">*</span>발송일시</label>
                    <div class="col-md-10 pl-0 pr-0 form-inline">
                      <div class="col-4 pl-0">
                        <el-select v-model="search.startYearValue"
                                   filterable
                                   placeholder="연도 선택"
                                   @change="resetDate('START','YEAR')">
                          <el-option v-for="option in yearList"
                                     :key="option.label"
                                     :label="option.label"
                                     :value="option.value">
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-3 pl-0">
                        <el-select v-model="search.startMonthValue"
                                   filterable
                                   placeholder="월 선택"
                                   @change="resetDate('START','MONTH')">
                          <el-option v-for="option in monthList"
                                     :key="option.label"
                                     :label="option.label"
                                     :value="option.value">
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-3 pl-0">
                        <el-select v-model="search.startDayValue"
                                   filterable
                                   placeholder="일 선택"
                                   @change="resetDate('START','DAY')">
                          <el-option v-for="option in dayList"
                                     :key="option.label"
                                     :label="option.label"
                                     :value="option.value">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                </div>
                <div class="mb-0 col-md-2 form-group pl-0 pr-0"></div>

                <div class="mb-0 col-md-4 form-group pl-0 pr-0" style="margin-top: 1rem;">
                  <label class="col-md-4 col-form-label form-control-label">전화번호</label>
                  <div class="col-md-8 pl-0 pr-0 form-inline">
                    <div class="pl-0">
                      <input type="text" class="form-control" placeholder="전화번호 입력" aria-label="전화번호 입력" @keyup="onlyNumber" :maxlength="15"
                             v-model="search.keyword"
                             @keydown.enter="retrieveWebSendResultList(1)">
                    </div>
                  </div>
                </div>

                <div class="mb-0 col-md-3 form-group pl-0 pr-0">
                  <label class="col-md-4 col-form-label form-control-label">성공여부</label>
                  <div class="col-md-8 pl-0 pr-0 form-inline">
                    <div class="pl-0">
                      <el-select v-model="search.successYn"
                                 filterable
                                 placeholder="성공여부">
                        <el-option v-for="option in selectSuccessOptions"
                                   :key="option.value"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>

                <div class="mb-0 col-md-4 form-group pl-0 pr-0">
                  <label class="col-md-4 col-form-label form-control-label">발송타입</label>
                  <div class="col-md-8 pl-0 pr-0 form-inline">
                    <div class="pl-0">
                      <el-select v-model="search.reservedCd"
                                 filterable
                                 placeholder="발송타입">
                        <el-option v-for="option in selectReserveOptions"
                                   :key="option.value"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>

<!--                <div class="mb-0 col-md-1 form-group pl-0 pr-0" style="margin-top: 0.5rem !important;">-->
<!--                </div>-->
                <div class="form-inline" style="margin-top: 1rem;">
                  <base-button size="lg" outline type="primary" @click="retrieveWebSendResultList(1)">검색</base-button>
                </div>
              </div>
            </div>
            <div class="col-lg-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="form-inline" style="margin-top: 1rem;">
                <base-input additional-classes="width-100" style="margin-bottom: 1rem;">
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page">
                    <el-option
                      class="select-primary"
                      v-for="item in this.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </base-input>
                <div class="form-control-label" style="margin-left: 1rem;">건씩 보기</div>
              </div>
              <div class="form-inline" style="margin-bottom: 1rem;">
                <div class="form-control-label" style="margin-right: 1rem;">
                  <span class="text-danger"> * 예약취소는 전송 5분전까지만 가능합니다.</span></div>
                <base-button type="danger" @click="requestReserveCancel">예약취소</base-button>
              </div>
            </div>

            <el-table :data="webSendResultList"
                      ref="webSendResultList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'조회된 정보가 없습니다.'"
                      @selection-change="selectionChange"
                      class="table-responsive table-flush">
              <el-table-column type="selection"
                               align="left"
                               :selectable="selectChk">
              </el-table-column>
<!--              <el-table-column label="번호" min-width="6%">-->
<!--                <template slot-scope="scope">-->
<!--                  {{ pagination.total - (pagination.currentPage-1)*pagination.perPage-scope.$index }}-->
<!--                </template>-->
<!--              </el-table-column>-->
              <el-table-column prop="regDate" label="발송일시" min-width="12%">
                <template slot-scope="scope">
                  {{ scope.row.regDate }}
                </template>
              </el-table-column>
              <el-table-column prop="endDate" label="결과수신일" min-width="12%">
                <template slot-scope="scope">
                  {{ scope.row.endDate }}
                </template>4
              </el-table-column>
              <el-table-column prop="endDate" label="수신번호" min-width="12%">
                <template slot-scope="scope">
                  {{ scope.row.phoneNumber }}
                </template>
              </el-table-column>
              <el-table-column prop="senderNo" label="발신번호" min-width="12%">
                <template slot-scope="scope">
                  {{ scope.row.senderNo }}
                </template>
              </el-table-column>
              <el-table-column prop="endDate" label="타입" min-width="9%">
                <template slot-scope="scope">
                  {{ scope.row.msgType }}
                </template>
              </el-table-column>
              <el-table-column prop="reservedCd" label="발송타입" min-width="9%">
                <template slot-scope="scope">
                  {{ scope.row.reservedCd === 'R' ? '예약' : '즉시' }}
                </template>
              </el-table-column>
              <el-table-column prop="statusCode" label="발송여부" min-width="8%">
                <template slot-scope="scope">
                  {{ scope.row.statusCode | statusCodeFilter }}
                </template>
              </el-table-column>
              <el-table-column prop="offerCode" label="카카오코드" min-width="10%">
                <template slot-scope="scope">
                  <el-popover placement="bottom" v-if="scope.row.kkoCd != null && scope.row.kkoCd != ''">
                    <span>{{ scope.row.kkoCodeDesc }}</span>
                    <base-button slot="reference" size="sm" type="default">{{ scope.row.kkoCd }}</base-button>
                  </el-popover>
                  <div v-else>
                    {{ scope.row.kkoCd }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="smsCd" label="문자코드" min-width="10%">
                <template slot-scope="scope">
                  <el-popover placement="bottom" v-if="scope.row.smsCd != null && scope.row.smsCd != ''">
                    <span>{{ scope.row.smsCodeDesc }}</span>
                    <base-button slot="reference" size="sm" type="default">{{ scope.row.smsCd }}</base-button>
                  </el-popover>
                  <div v-else>
                    {{ scope.row.smsCd }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="kkoMsg" label="메시지내용" min-width="10%">
                <template slot-scope="scope">
                  {{ scope.row.kkoMsg }}
                </template>
              </el-table-column>
              <el-table-column prop="seqNo" label="상세보기" min-width="10%">
                <template slot-scope="scope">
                  <base-button type="info" size="sm" @click="openDetailLayer(scope.row.seqNo)">
                    상세보기
                  </base-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="retrieveWebSendResultList">
            </base-pagination>

            <web-send-result-layer
              v-if="showDetailLayer"
              :seq-no="seqNo"
              :message-type="search.messageType"
              @close="closeLayer">
            </web-send-result-layer>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui'
  import commJsUtilMixin from '@/shared/mixins/commJsUtil'
  import { default as commFlatpickrMixin } from '@/shared/mixins/commFlatpickrMixin'
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX, RESULT_CODE } from '@/shared/util/const'
  import WebSendResultLayer from './WebSendResultLayer';
  import swal from "sweetalert2";

  export default {
    name: 'WebSendResult',
    mixins: [ commFlatpickrMixin, commJsUtilMixin, commPaginationMixin ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      WebSendResultLayer
    },
    created() {
      // 연도 목록 세팅
      let fullDays                = this._fullDays('Y', 0);
      this.yearList               = fullDays.yearList;
      this.search.startYearValue  = fullDays.year;

      // 월 목록 세팅
      this.monthList              = fullDays.monthList;
      this.search.startMonthValue = fullDays.month;

      // 일 목록 세팅
      this.dayList                = fullDays.dayList;
      this.search.startDayValue   = fullDays.day;


      this.setParams(1)

    },
    filters: {
      convertDatetimeFilter : value => {
        if (value) {
          return value.replace(" ", '<br />');
        } else if (value === null) {
          return '-'
        } else {
          return value
        }
      },
      statusCodeFilter: value => {
        let name = '';
        switch (value) {
          case 'N':
            name = '대기';
            break;
          case 'Y':
            name = '발송';
            break;
          case 'C':
            name = '취소';
            break;
          case 'H':
            name = '발송 준비 중';
            break;
          case 'P':
            name = '이미지 전송 중';
            break;
        }
        return name;
      },
    },
    data() {
      return {
        showDetailLayer   : false,
        seqNo             : undefined,
        webSendResultList : [],
        yearList          : [],
        monthList         : [],
        dayList           : [],
        perPageOptions: [10, 50, 100],
        selectedRows      : [],
        selectDateOptions : [
          {
            label: '연별',
            value: 'YEAR'
          },
          {
            label: '월별',
            value: 'MONTH'
          },
          {
            label: '일별',
            value: 'DAY'
          }
        ],
        MessageTypeOptions: [
          {
            label: '알림톡',
            value: 'AT'
          },
          {
            label: '친구톡',
            value: 'FT'
          },
          {
            label: 'SMS',
            value: 'SM'
          },
          {
            label: 'MMS/LMS',
            value: 'MM'
          }
        ],
        selectSuccessOptions : [
          {
            label: '선택',
            value: ''
          },
          {
            label: '성공',
            value: 'Y'
          },
          {
            label: '실패',
            value: 'N'
          }
        ],
        selectReserveOptions : [
          {
            label: '선택',
            value: ''
          },
          {
            label: '예약',
            value: 'R'
          },
          {
            label: '즉시',
            value: 'I'
          }
        ],
        search: {
          dateTypeCondition : 'DAY' ,
          startYearValue    : null  ,
          startMonthValue   : null  ,
          startDayValue     : this.$moment(new Date()).format('YYYY-MM-DD') ,
          messageType       : null  ,
          successYn         : '',
          reservedCd        : ''
        }
      }
    },
    methods: {
      /**
       * 알럿메시지 출력
       */
      alertMsg(msg , title){
        let titleMsg = title ? title : '경고';
        let type = title ? 'info' : 'warning';
        swal({
          title: titleMsg,
          text: msg,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-'+type,
          type: type,
          width: 400
        });
      },

      /**
       * 정산정보 목록 조회
       */
      retrieveWebSendResultList(page) {
        let chk = true;

        if(this.checkNull(this.search.messageType)){
          this.alertMsg('메시지유형을 선택해 주세요.');
          chk = false;
          return false;
        }

        if(this.checkNull(this.search.startMonthValue) || this.checkNull(this.search.startDayValue)) {
          this.alertMsg('조회일 선택해 주세요.');
          chk = false;
          return false;
        }

        if(chk){
          let pageNo = page ? page : this.pagination.currentPage;
          this.$axios.get(`${USER_API_PREFIX}/web-send/result`, {
            params: this.setParams(pageNo)
          }).then(res => {
            this.webSendResultList      = res.data.result.webSendResultList; // 목록정보 세팅
            this.pagination.currentPage = pageNo; // 페이지 번호 세팅
            this.pagination.total       = res.data.result.total; // 전체페이지수 세팅
          });
        }
      },

      /**
       * 연도/월/일자에 선택에 따른 날짜 초기화
       */
      resetDate(type, period) {
        switch (period) {
          case 'YEAR' :
            this.search.startMonthValue = ''
            this.search.startDayValue   = ''
            break
          case 'MONTH' :
              this.search.startDayValue   = ''
            break
        }
      },

      /**
       * 상세 레이어 생성
       */
      openDetailLayer(seqNo) {
        this.showDetailLayer = true;
        this.seqNo = seqNo;
      },

      /**
       * 레이어 닫기
       */
      closeLayer() {
        this.showDetailLayer = false;
      },

      /**
       * 키 이벤트로 오직 숫자만 입력되도록 처리
       */
      onlyNumber() {
        this.search.keyword = this._onlyNumber(this.search.keyword);
      },

      /**
       * null check
       */
      checkNull(param){
        if(param === undefined || param === null || '' == param){
          return true;
        }else{
          return false;
        }
      },

      /**
       * 일별/월별에 따른 파라미터 세팅
       */
      setParams(pageNo) {
        return {
          page          : pageNo ? pageNo : 1               ,
          rows          : Number(this.pagination.perPage)   ,
          startYear     : this.search.startYearValue        ,
          startMonth    : this.search.startMonthValue       ,
          startDay      : this.search.startDayValue         ,
          endYear       : this.search.startYearValue        ,
          endMonth      : this.search.startMonthValue       ,
          endDay        : this.search.startDayValue         ,
          searchKeyword : this.search.keyword               ,
          successYn     : this.search.successYn             ,
          messageType   : this.search.messageType           ,
          reservedCd    : this.search.reservedCd
        }
      },
      /**
       * 취소자 선택 이벤트
       * @param selectedRows
       */
      selectionChange(selectedRows){
        this.selectedRows = selectedRows;
      },
      /**
       * 예약취소 가능 조건
       * @param row
       * @returns {boolean}
       */
      selectChk(row){
        return row.reservedCd === 'R' && row.statusCode === 'N';
      },
      /**
       * 예약 취소 요청
       */
      requestReserveCancel() {
        if(this.selectedRows.length === 0) {
          swal({
            title: `발송예약 취소요청`,
            text: `취소요청할 예약건을 선택해주세요.`,
            confirmButtonText: '확인',
            type: 'warning'
          });
          return false;
        }

        swal({
          title: `예약취소 요청`,
          text: this.selectedRows.length + '건 선택되었습니다.\n\n예약취소 하시겠습니까?',
          type: 'question',
          showCancelButton: true,
          confirmButtonText: '예',
          cancelButtonText: '아니오',
          reverseButtons: true
        }).then((result) => {
          if(result.value) {
            // 예약취소 목록 파싱
            let realTimeResultList = [];
            this.selectedRows.forEach(val => {
              realTimeResultList.push({
                clientId: val.clientId,
                seqNo: val.seqNo
              });
            });

            // 취소 요청
            this.$axios.post(`${USER_API_PREFIX}/web-send/cancel`, {
              messageType : this.search.messageType,
              realTimeResultList: realTimeResultList
            }).then(res => {
              if (res.data.result.code === RESULT_CODE.SUCCESS) {
                let jobCnt = res.data.result.jobCnt;
                let failCnt = res.data.result.failCnt;
                swal({
                  title: `[예약취소 요청결과]`,
                  text: `예약취소성공 : ${jobCnt - failCnt} 건 , \n\n예약취소실패 : ${failCnt} 건`,
                  buttonsStyling: false,
                  confirmButtonClass: 'btn btn-success',
                  type: 'success'
                }).then(res => {
                  if(result.value) {
                    this.retrieveWebSendResultList();
                  }
                });
              } else {
                this.notify('warning', res.data.result.message);
              }
            });
          }
        });
      },
    }
  }
</script>

